import { IconButton, Stack, Typography } from "@mui/material";
import * as React from "react";
import { useEffect, useMemo, useState } from "react";
import AddStats from "./AddStats";
import { numberFormatter, useIsMobileHook } from "../../../utils";
import { FactionIcon } from "../../../components/Images";
import AddIcon from "@mui/icons-material/Add";
import { Remove, Share } from "@mui/icons-material";
import { unlinkStats } from "../../../api/user/UpdateUser";
import { useTranslation } from "react-i18next";
import { Namespaces } from "../../../config/localisation/Localisation";
import { SimpleButton } from "./StatsTracking";
import _ from "underscore"
import { useComponentToImage } from './../../../utils';

const unlink = (e) => unlinkStats().then(() => {
    e.stopPropagation()
    window.location.reload()
})

const rankOrder = {
    None: 0,
    BRONZE: 0,
    SILVER: 1,
    GOLD: 2,
    RUBY: 3,
    EMERALD: 4,
    DIAMOND: 5,
    LEGENDS: 6
}

const rankSort = (a, b) => {
    const bRank = b.rank.split(' ');
    const aRank = a.rank.split(' ');
    const rankDif = rankOrder[bRank[0]] - rankOrder[aRank[0]]
    if (rankDif !== 0) return rankDif
    return parseInt(bRank[1]) - parseInt(aRank[1])
}

export const Categories = {
    PROGRESSION: 'progression',
    KILLS: 'kills',
    MATCHES: 'matches',
    MVP: 'mvp',
    DAMAGE: 'damage',
    SCORE: 'score',
    ASSISTS: 'assists',
    ABILITIES: 'abilities'
}

export const StatsSpans = {
    CAREER: { name: 'career', getter: (stats) => stats.latest },
    S0: {
        name: 'preseason',
        getter: (stats) => stats.seasonal['0']?.abs,
        careerComparison: 'CAREER',
    },
    S1: {
        name: 'season',
        nameSuffix: ' 1',
        getter: (stats) => stats.seasonal['1']?.dif,
        startDate: 1719878400000,
        careerComparison: 'CAREER',
    },
    Session: {
        name: 'session',
        getter: session => session.overall,
        session: true,
        careerComparison: 'CAREER',
    },
    Today: {
        name: 'Today',
        getter: (stats) => stats.today,
        careerComparison: 'CAREER',
    },
}

export const Stats = [
    {
        label: 'level',
        path: 'progression.level',
        category: Categories.PROGRESSION,
        filters: ['session']
    },
    {
        label: 'rank',
        path: 'progression.rank',
        path: 'progression.rank',
        category: Categories.PROGRESSION,
        graphValue: rank => { console.log(rankOrder[rank.split(' ')[0]], rank.split(' ')[1], (rankOrder[rank.split(' ')[0]] * 10) + parseInt(rank.split(' ')[1] ?? 0)); return (rankOrder[rank.split(' ')[0]] * 10) + parseInt(rank.split(' ')[1] ?? 0) },
        revGraphValue: rank => rank < 1 || rank > 70 ? 'None' : `${Object.entries(rankOrder).find(e => e[0] !== 'None' && e[1] === Math.floor((rank - 1) / 10))[0]} ${Math.floor(((rank - 1) % 10) + 1)}`,
        filters: ['session']
    },
    {
        label: 'kdr',
        path: 'adj',
        category: Categories.KILLS,
        rounding: { panel: 2 },
        gameDiff: 'kdr',
        careerDiff: 'kdr'
    },
    // {
    //     label: 'rating',
    //     path: 'rating',
    //     category: Categories.KILLS,
    //     filters: ['session']
    // },
    // {
    //     label: 'adjustedKdRatio',
    //     path: 'adj',
    //     path: 'adj',
    //     category: Categories.KILLS,
    //     rounding: {panel: 2},
    //     gameDiff: 'adjustedKdRatio'
    // },
    {
        label: 'kills',
        path: 'kills.total',
        category: Categories.KILLS,
        gameDiff: 'killsPerGame'
    },
    {
        label: 'killsPerGame',
        path: 'kills.killsPerGame',
        category: Categories.KILLS,
        rounding: { panel: 1 },
        filters: ['session'],
        careerDiff: 'killsPerGame'

    },
    {
        label: 'deaths',
        path: 'factions.adjustedTotal',
        category: Categories.KILLS,
        rounding: { panel: 2 },
        gameDiff: '-deathsPerGame',
    },
    {
        label: 'deathsPerGame',
        path: 'dpg',
        category: Categories.KILLS,
        rounding: { panel: 2 },
        filters: ['session', 'panel'],
        careerDiff: '-deathsPerGame'
    },
    {
        label: 'assists',
        path: 'kills.assists',
        category: Categories.ASSISTS,
        gameDiff: 'assistsPerGame',
    },
    {
        label: 'assistRate',
        path: 'kills.assistRate',
        category: Categories.ASSISTS,
        rounding: { panel: 1 },
        unit: '%',
        filters: ['session'],
        careerDiff: 'assistRate'
    },
    {
        label: 'assistsPerGame',
        path: 'kills.assistsPerGame',
        category: Categories.ASSISTS,
        rounding: { panel: 1 },
        filters: ['session'],
        careerDiff: 'assistsPerGame'
    },
    {
        label: 'wins',
        path: 'matches.wins',
        category: Categories.MATCHES,
        filters: ['session']
    },
    {
        label: 'losses',
        path: 'matches.losses',
        category: Categories.MATCHES,
        filters: ['session']
    },
    {
        label: 'winRate',
        path: 'matches.winRate',
        category: Categories.MATCHES,
        rounding: { panel: 1 },
        unit: '%',
        filters: ['session'],
        careerDiff: 'winRate'
    },
    {
        label: 'winRatio',
        path: 'matches.winRatio',
        category: Categories.MATCHES,
        rounding: { panel: 2 },
        filters: ['session'],
        careerDiff: 'winRatio'
    },
    {
        label: 'winStreak',
        path: 'matches.winStreak.best',
        category: Categories.MATCHES,
        filters: ['session', 'today'],
    },
    {
        label: 'mvpCount',
        path: 'mvp.count',
        category: Categories.MVP,
        filters: ['session']
    },
    {
        label: 'mvpRate',
        path: 'mvp.rate',
        category: Categories.MVP,
        rounding: { panel: 1 },
        unit: '%',
        filters: ['session'],
        careerDiff: 'mvpRate'
    },
    {
        label: 'score',
        path: 'score.total',
        category: Categories.SCORE,
        gameDiff: 'scorePerGame',
    },
    {
        label: 'scorePerGame',
        path: 'score.perGame',
        category: Categories.SCORE,
        filters: ['session'],
        careerDiff: 'scorePerGame'
    },
    {
        label: 'scorePerMin',
        path: 'score.perMin',
        category: Categories.SCORE,
        filters: ['session'],
        careerDiff: 'scorePerMin'
    },
    {
        label: 'damage',
        path: 'kills.damage',
        category: Categories.DAMAGE,
        gameDiff: 'damagePerGame'
    },
    {
        label: 'damagePerGame',
        path: 'kills.damagePerGame',
        category: Categories.DAMAGE,
        filters: ['session'],
        careerDiff: 'damagePerGame'
    },
    {
        label: 'damagePerKill',
        path: 'kills.damagePerKill',
        category: Categories.DAMAGE,
    },
    {
        label: 'abilityKills',
        path: 'kills.ability',
        category: Categories.ABILITIES
    },
    {
        label: 'abilitiesUsed',
        path: 'abilities.abilities',
        category: Categories.ABILITIES
    },
    {
        label: 'ultrasUsed',
        path: 'abilities.ultras',
        category: Categories.ABILITIES
    },
]

const UserStats = (props) => {
    return props.stats?.latest ? <StatsPanel alwaysExpanded={props.expanded} {...props} />
        : props.isOwnProfile ? <AddStats />
            : null
}

const mainStats = ['kdr', 'winRatio', 'wins', 'mvpCount', 'mvpRate', 'killsPerGame', 'kills']
const mobileMainStats = ['kdr', 'winRatio', 'mvpRate', 'kills', 'killsPerGame']

function getStatDiff(stat, stats, compare) {
    if (stat.careerDiff == null || Stats.find(s => s.label === stat.careerDiff.replaceAll('-', '')) == null) return null
    const comparisonStat = getStat(compare, Stats.find(s => s.label === stat.careerDiff.replaceAll('-', '')));
    console.log(stat, getStat(stats, stat), comparisonStat)
    const diff = (getStat(stats, stat) - comparisonStat) / comparisonStat
    const lowerBetter = stat.careerDiff.startsWith('-')
    return Math.round(diff * (lowerBetter ? -100 : 100))
}

const StatDiff = ({ stat, stats, compare }) => {
    const gameDiff = getStatDiff(stat, stats, compare)

    return gameDiff == null ? null
        : <Stack direction='row' m='3px 0  0 8px' alignItems='center'>
            <Typography fontSize='0.78rem' color={gameDiff > 0 ? 'green' : 'red'}>{gameDiff}%</Typography>
            <Typography sx={{ color: 'white', fontSize: '0.5rem', pb: '2px' }}>{gameDiff > 0 ? '▲' : '▼'}</Typography>
        </Stack>
}

const PlayerStat = ({ stat, data, compare }) => {
    const { t } = useTranslation(Namespaces.playerStats)
    const isMobile = useIsMobileHook()

    const extraWidth = t(stat.label).length > 12 || (isMobile ? false : renderValue(stat, data)?.toString()?.length > 6) || (compare && stat.careerDiff)

    function width() {
        if (isMobile) {
            // if (stat.label.length > 15) return '
            return extraWidth ? '95px' : '80px'
        } else {
            return extraWidth ? '105px' : '85px'
        }
    }


    return <Stack m={isMobile? '3px' : '5px'} alignItems='start' key={`stat-${stat.label}`}
        width={width()} p={isMobile ? '3px 5px' : '4px 8px'} backgroundColor='rgba(0,0,0,0.2)'
        borderRadius='5px'
        border='1px solid #444'>
        <Typography variant='small' color='#ccc'>{t(stat.label)}</Typography>
        <Stack direction='row' alignItems='center'>
            <Typography variant={isMobile ? 'midSmallHead' : 'midHead'}>{renderValue(stat, data)}</Typography>
            {compare && <StatDiff stat={stat} stats={data} compare={compare} />}
        </Stack>
    </Stack>
}

export const LevelIcon = ({ level, small }) => {
    return level == null ? null : <Typography variant='mid' sx={{
        fontSize: small ? level.toString().length > 2 ? '0.7rem' : '0.8rem'
            : level.toString().length > 2 ? '1rem' : '1.25rem',
        borderRadius: 15,
        border: '2px solid white',
        display: 'inline-flex',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        height: small ? '22px' : '30px',
        width: small ? '22px' : '30px'
    }}>{level}</Typography>;
}

export const RankIcon = ({ rank, small }) => {
    if (rank == null) return null
    let rankImgName = rank.toLowerCase().replaceAll('_', '').replaceAll(' ', '')
    rankImgName = rankImgName.charAt(0).toUpperCase() + rankImgName.slice(1)
    try {
        return <img src={require(`../../../assets/game/rank/${rankImgName}.png`)} alt={rankImgName}
            style={{ height: small ? '25px' : '45px', width: small ? '25px' : '45px', paddingTop: '2px' }} />
    } catch {
        return null
    }
}

export function PlayerProgression({ stats, small = false }) {
    const isMobile = useIsMobileHook()
    return stats == null ? null : <Stack direction='row' alignItems='center' justifyContent='center'
        m={isMobile && !small ? '0 10px' : '0 5px 0 0'}
        width={small ? '60px' : stats.level && stats.rank ? '100px' : '60px'}>
        <LevelIcon level={stats.level} small={small} />
        <Stack minWidth='10px' />
        <RankIcon rank={stats.rank} small={small} />
    </Stack>
}

const MainStats = ({ stats }) => {
    const isMobile = useIsMobileHook()
    return <Stack direction='row' alignItems='center' justifyContent='stretch'>
        <Stack direction='row' alignItems='center' justifyContent={isMobile ? 'start' :'center'} flexGrow={1}
            flexWrap={isMobile ? 'wrap' : 'nowrap'} overflow='hidden' mr={isMobile ? '25px': 0}>
            <PlayerProgression stats={stats.progression} />
            {Stats.filter(s => (isMobile ? mobileMainStats : mainStats).includes(s.label))
                .map(stat => <PlayerStat key={`main-stats-${stat.label}`} data={stats} stat={stat} />)}
        </Stack>
    </Stack>
}

const StatsTable = ({ title, stats, columns, width, columnWidths, excludeColumns = [] }) => {
    const { t } = useTranslation([Namespaces.playerStats, Namespaces.weapons])
    const isMobile = useIsMobileHook()

    const rows = useMemo(() => Object.entries(stats)
        .map(e => [e[0], ...(typeof e[1] === 'object' ? Object.entries(e[1]).filter(en => !excludeColumns.includes(en[0])).map(en => en[1]) : [e[1]])]), [stats]);

    const headers = useMemo(() => [...columns, ...Object.keys(Object.values(stats)[0]).filter(c => !excludeColumns.includes(c))], [stats]);

    return stats ? <Stack width={isMobile ? 'calc(100% - 20px)' : width} m='5px' p={isMobile ? '5px' : '10px 15px'}
        border='1px solid #aaa' borderRadius={3}>
        <Typography variant='midHead' m='0 0 10px 0px'>{title}</Typography>
        <Stack direction='row' p='3px' backgroundColor='rgba(100,100,100,0.3)' borderRadius='3px'>
            {headers.map((column, i) =>
                <Typography key={`title-${column}`} variant={isMobile ? 'small' : 'smallHead'} fontStyle='oblique'
                    width={columnWidths[i]}>
                    {t(column)}
                </Typography>)}
        </Stack>
        {rows.map((row, i) =>
            <Stack direction='row' p='4px 0 4px 4px' key={row[0] + '-row'}
                backgroundColor={`rgba(70,70,70,${0.1 * (i % 2)})`}
                borderRadius={i === stats.length - 1 ? '0 0 3px 3px' : '0'}>
                {row.map((stat, i) =>
                    <Typography key={`${row[1]}-${headers[i]}`} variant='small' width={columnWidths[i]}>
                        {renderValue(Stats.find(s => s.label === headers[i]), stat)}
                        {/*{`${stat > 1000 ? numberFormatter.format(stat) : t(stat, {ns: [Namespaces.playerStats, Namespaces.weapons]}) ?? 0}${Stats.find(s => s.label === headers[i])?.unit ?? ''}`}*/}
                    </Typography>)}
            </Stack>
        )}
    </Stack> : null
}

const AllStats = ({ stats, isOwnProfile, career, lastUpdate }) => {
    const { t } = useTranslation(Namespaces.playerStats)
    const isMobile = useIsMobileHook()


    const categories = new Set(Stats.map(st => st.category).filter(cat => cat !== Categories.PROGRESSION))

    return <Stack>
        <Stack direction='row' alignItems='stretch' justifyContent='start' flexGrow={1} flexWrap='wrap'>
            <Stack m='5px' p='3px' direction='column' alignItems='start'
                border='1px solid #aaa' borderRadius={3}>
                <Typography variant='smallHead' color='white' fontStyle='oblique' ml='10px'
                    height='100%'>{t('progression')}</Typography>
                <Stack direction='row' alignItems='center' p={isMobile ? '6px' : '12px 15px'}>
                    <PlayerProgression stats={stats.progression} />
                </Stack>
            </Stack>
            {[...categories].map(cat =>
                <Stack m='5px' key={`stat-cat-${cat}`} p='3px' direction='column' alignItems='start'
                    border='1px solid #aaa' borderRadius={3}>
                    <Typography variant='smallHead' color='white' fontStyle='oblique'
                        ml='10px'>{t(cat.toLowerCase())}</Typography>
                    <Stack direction='row' alignItems='center' flexWrap='wrap'>
                        {Stats.filter(st => st.category === cat).map(stat =>
                            <PlayerStat key={`${cat}-stat-${stat.label}`} stat={stat} data={stats} compare={career} />)}
                    </Stack>
                </Stack>)}
            <Stack m='5px' p='3px' direction='column' alignItems='start'
                border='1px solid #aaa' borderRadius={3}>
                <Typography variant='smallHead' color='white' fontStyle='oblique' ml='10px'
                    height='100%'>{t('favouriteFaction')}</Typography>
                <Stack direction='row' alignItems='center' justifyContent='center'
                    p={isMobile ? '6px 8px' : '10px 10px'}>
                    <FactionIcon faction={stats.factions.favourite} style={{ height: '40px', width: '40px' }} />
                    <Typography variant='midHead' ml='5px'>{stats.factions.favourite === 'GSG 9' ? 'GS-Kommando' : stats.factions.favourite}</Typography>
                </Stack>
            </Stack>
        </Stack>
        <Stack direction={isMobile ? 'column' : 'row'} justifyContent='space-between' width='100%'>
            <StatsTable title={t('weaponClasses')} stats={stats.kills.weaponClasses} width='33%' columns={['weaponClass', 'kills']}
                columnWidths={['60%', '40%']} />
            <StatsTable title={t('gamemodes')} stats={stats.matches.gamemodes} width='66%' columns={['gamemode']}
                columnWidths={['30%', '15%', '15%', '20%', '20%']} excludeColumns={['total', 'draws']} />
        </Stack>
        <Stack direction='row' alignSelf='stretch' alignItems='center' justifyContent='space-between' mt='3px'
            p='0 5px'>
            {isOwnProfile ? <Typography variant='smallHead' color='white' p='3px 5px' borderRadius={3}
                backgroundColor='rgba(0,0,0,0.2)' sx={{ cursor: 'pointer' }} onClick={unlink}>
                {t('unlinkStats')}
            </Typography> : <Stack />}
            <Typography variant='small' fontStyle='oblique' color='#aaa'>
                {`${t('lastUpdated')}: ${new Date(lastUpdate * 1000).toLocaleString()}`}
            </Typography>
        </Stack>
    </Stack>

}

const statsMessage = (t) => <><i>{t('statsMayDiffer')}</i> {t('statsFromUbi')} <a style={{ color: 'white' }} href="/updates" target='_blank' rel='noopener noreferrer'><i>{t('moreHere')}</i></a></>

export const StatsPanel = ({ isOwnProfile, stats, alwaysExpanded = false, statsSpan, setStatsSpan, session }) => {
    const { t } = useTranslation(Namespaces.playerStats)
    const [expanded, setExpanded] = useState(false)
    const isMobile = useIsMobileHook()
    const { ref, convert } = useComponentToImage('stats', { height: undefined, width: undefined })
    useEffect(() => { setExpanded(alwaysExpanded ? true : expanded) }, [alwaysExpanded, expanded])

    const statsNoteHeight = isMobile ? '50px' : '20px';
    return <Stack direction='column' alignItems='stretch' justifyContent='center'
        sx={{
            position: 'relative',
            mt: '15px',
            height: expanded ? 'auto' : isMobile ? '145px' : '60px',
            width: `calc(100% - ${isMobile ? '12px' : '50px'})`,
            p: isMobile ? '10px 5px' : '10px 25px',
            borderRadius: '15px',
            border: '1px solid #aaa',
            color: 'white',
            backgroundColor: 'rgba(0,0,0,0.2)',
        }}>
        <Stack position='absolute' top='-1px' left='-1px' width='100%' height={statsNoteHeight}
            backgroundColor='#008cff3d' alignItems='center' justifyContent='center' borderRadius='15px 15px 0 0'
            border='1px solid #aaa'>
            <Typography variant='small' textAlign='center' sx={{ textShadow: '0 0 4px black' }}
                m='2px 5px'>{statsMessage(t)}</Typography>
        </Stack>
        <Stack display={alwaysExpanded ? 'none' : null} position='absolute' zIndex={100} top={statsNoteHeight} right={isMobile ? '2px' : '10px'} justifyContent='center'
            height={isMobile ? '50px' : '62px'}>
            <IconButton onClick={() => setExpanded(!expanded)}>
                {expanded ? <Remove sx={{ color: 'white' }} /> : <AddIcon sx={{ color: 'white' }} />}
            </IconButton>
        </Stack>
        <Stack minHeight={statsNoteHeight} />
        <Stack ref={ref} position='relative' m={isMobile ? '0' : '0 3px'}>
            <div style={{ height: '100%', width: '100%', backgroundColor: '#222', position: 'absolute', zIndex: -1000 }} />
            <Stack direction='row'>
                {!expanded ? null : Object.values(StatsSpans).filter(ss => !ss.session || session != null).map(ss =>
                    (ss.session ? ss.getter(session) : ss.getter(stats)) == null ? null : <SimpleButton onClick={e => { setStatsSpan(ss); e.stopPropagation() }} selected={statsSpan === ss} text={ss.name} suffix={ss.nameSuffix} key={ss.name + '-button'} />)}
                {expanded && !isMobile && <IconButton onClick={convert} sx={{ color: 'white' }}>
                    <Share sx={{ fontSize: '1.5rem' }} />
                </IconButton>}
            </Stack>
            {!expanded ? <MainStats stats={statsSpan.session ? statsSpan.getter(session) : statsSpan.getter(stats)} career={StatsSpans.CAREER.getter(stats)} />
                : <AllStats isOwnProfile={isOwnProfile} stats={statsSpan.session ? statsSpan.getter(session) : statsSpan.getter(stats)} career={StatsSpans[statsSpan.careerComparison]?.getter(stats)} lastUpdate={stats.lastUpdate} />}
        </Stack>
    </Stack>
}

export const renderValue = (stat, value) => {
    value = typeof value === 'object' ? getStat(value, stat) : value
    const round = stat?.rounding?.panel ?? 0
    value = typeof value === 'string' ? value : Math.round(value * (Math.pow(10, round))) / Math.pow(10, round)
    return `${value > 1000 ? numberFormatter.format(value) : value ?? 0}${stat?.unit ?? ''}`
}

export const getStat = (value, stat) => _.get(value, stat.path.split('.'))

export default UserStats